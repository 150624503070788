import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { FaHome, FaChartLine, FaExchangeAlt, FaTwitter } from 'react-icons/fa';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  tokenOverlay: {
    position: 'absolute', // Changed from fixed to absolute
    top: 0, // Align to the top
    left: 50, // Align to the left
    zIndex: 1300, // Keep on top
    padding: '0px',
    color: 'white', // Set the text color to white
  },
  tokenContainer: {
    display: 'flex',
    flexDirection: 'row', // Stack items vertically
    gap: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    color: 'white',
    margin: '0px auto',
    maxWidth: '100%',
    maxHeight: '180px', 
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    padding: '10px',
    borderRadius: '5px',
    fontFamily: '"Arial", sans-serif',
    lineHeight: 1.6,
    color: '#333',
  },
  tokenContainerMobile: {
    display: 'flex',
    flexDirection: 'row', // Stack items vertically
    gap: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
    color: 'white',
    margin: '10px auto',
    maxWidth: '100%',
    maxHeight: '530px', 
    padding: '0px',
    fontFamily: '"Arial", sans-serif',
    lineHeight: 1.6,
    color: '#333',
  },
  tokenHeader: {
    display: 'flex',
    alignItems: 'top', // Align items vertically
    paddingBottom: '10px', // Add some padding at the bottom of the header
    color: 'white,'
  },
  tokenImage: {
    width: '32px',
    height: '32px',
    marginRight: '20px',
  },
  tokenInfo: {
    paddingRight: '50px',
    color: 'white',
    '& h1': {
      margin: 0,
      padding: 0,
      color: '#007bff',
    },
    '& p': {
      margin: '0px 0', // Reduced vertical margin from 10px to 5px
    },
    '& a': {
      color: '#007bff',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  descriptionArea: {
    color: 'white',
    maxHeight: '320px', // Set a maximum height
    maxWidth: '500px', // Set a maximum height
    overflowY: 'auto', // Enable vertical scrolling
    padding: '0px', // Optional: add some padding
    marginTop: '5px', // Optional: add some space between the token info and the description
  },
  copyIcon: {
    color: 'white',
    cursor: 'pointer',
    marginLeft: '5px',
    '&:hover': {
      color: '#007bff',
    },
  },
  iconLinks: {
    display: 'flex', // Align icons in a row
    justifyContent: 'start', // Align items to the start of the container
    alignItems: 'center', // Vertically align icons in the middle
    gap: theme.spacing(2), // Create space between icons

    // If you want the icons to be vertically aligned with the text
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),

    '& a': {
      textDecoration: 'none', // Remove underline from links
      color: 'inherit', // Icons take the color from the parent element
    },

    '& a:hover': {
      // Hover styles for the links
      opacity: 0.7, // Slight fade effect on hover
    },
  },
  linkIcon: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary.main, // Changes color on hover
    },
    // Add other styles like size, padding etc.
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));


const TradingViewWidget = ({ data }) => {
  let currentSymbol = null;
 
  useEffect(() => {
    const appendScript = () => {
      var script = document.createElement('script');
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js";
      script.async = true;
      var symbol = data.symbol.toUpperCase();
  
      var settings = {
        "symbol": (symbol == "USDT") ? symbol : ("MEXC:" + symbol + "USDT"),
        "width": 350, 
        "height": 220,
        "locale": "en",
        "dateRange": "1D",
        "colorTheme": "dark", 
        "isTransparent": true,
        "autosize": false,
        "largeChartUrl": ""
      };
      
      script.innerHTML = JSON.stringify(settings);
      
      const container = document.querySelector('.tradingview-widget-container__widget');
      if (container) {
        while (container.firstChild) {
          container.removeChild(container.firstChild);
        }
        container.appendChild(script);
      }
    };

    const newSymbol = data.symbol.toUpperCase();
    if (!currentSymbol || (currentSymbol != newSymbol)) {
      appendScript();
      currentSymbol = newSymbol;
    }
  }, [data]); // Dependency array includes data to ensure the effect runs again if data changes

  
  return (
    <div className="tradingview-widget-container" id="tradingview-widget-container">
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
};


export default function TokenInfoComponent({ data, onClose, isMobile, isPortrait }) {
  const classes = useStyles();

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  function formatShortAddress(address) {
    if (address && address.length > 8) {
      const start = address.substring(0, 6); // '0x' + first 4 characters
      const end = address.substring(address.length - 4); // last 4 characters
      return `${start}…${end}`; // Using the ellipsis character directly
    }
    return address;
  }
  
  return (
    <div style={{ height: (isMobile) ? '200px' : '300px' }} className={classes.tokenOverlay}>
      <div className={(isMobile) ? classes.tokenContainerMobile : classes.tokenContainer }>
        <div style={{ width: (isMobile) ? ((!isPortrait) ? '375px' : '330px' ) : '300px', marginLeft: '0px', paddingLeft: '0px' }} className={classes.tokenHeader}>
          <div style={{ width: (isMobile) ? ((!isPortrait) ? '375px' : '300px' ) : '300px', marginLeft: '0px', paddingLeft: '0px' }} className={classes.tokenInfo}>
            <h1><img src={data.image.large} alt={`${data.name} Logo`} className={classes.tokenImage} />{data.name}</h1>
            {!isMobile && (
              <div>
                <p><strong>Symbol:</strong> {data.symbol.toUpperCase()}</p>
                <p>
                <strong>Contract:</strong> {formatShortAddress(data.contract_address)}
                  <Tooltip title="Copy to clipboard">
                      <IconButton
                          size="small"
                          className={classes.copyIcon}
                          onClick={() => handleCopyToClipboard(data.contract_address)}
                      >
                      <FileCopyIcon fontSize="inherit" />
                      </IconButton>
                  </Tooltip>
                </p>
                <p><strong>Current Price:</strong> ${(data.market_data.current_price && data.market_data.current_price.usd) ? data.market_data.current_price.usd.toFixed(6) : ''}</p>
              </div>
            )}
            {data.market_data && data.market_data.market_cap && data.market_data.market_cap.usd && (
              <p><strong>Market Cap:</strong> ${data.market_data.market_cap.usd.toLocaleString()}</p>
            )}
            <div className={classes.iconLinks}>
              {data.links && data.links.homepage && (
                <Tooltip title="Go to Homepage">
                  <a href={data.links.homepage[0]} target="_blank" rel="noopener noreferrer">
                    <FaHome className={classes.linkIcon} />
                  </a>
                </Tooltip>
              )}
              {data.contract_address && (
                <Tooltip title="View Price History">
                  <a href={`https://etherscan.io/dex/uniswapv2/${data.contract_address}`} target="_blank" rel="noopener noreferrer">
                    <FaChartLine className={classes.linkIcon} />
                  </a>
                </Tooltip>
              )}
              {data.contract_address && (
                <Tooltip title="View Transactions">
                  <a href={`https://etherscan.io/address/${data.contract_address}`} target="_blank" rel="noopener noreferrer">
                    <FaExchangeAlt className={classes.linkIcon} />
                  </a>
                </Tooltip>
              )}
              {data.twitter_screen_name && (
                <Tooltip title="View on Twitter">
                  <a href={`https://twitter.com/${data.links.twitter_screen_name}`} target="_blank" rel="noopener noreferrer">
                    <FaTwitter className={classes.linkIcon} />
                  </a>
                </Tooltip>
              )}
              {data.contract_address && (
              <Tooltip title="UniSwap">
                <a href={`https://app.uniswap.org/tokens/ethereum/${data.contract_address}`} target="_blank" rel="noopener noreferrer">
                  <img src="https://cryptologos.cc/logos/uniswap-uni-logo.png" className={classes.linkIcon} width="32" height="32" />
                </a>
              </Tooltip>
              )}
            </div>
            {(data.description && data.description.en && isMobile && isPortrait) && (
              <div style={{ width: '325px', height: '200px', backgroundColor: 'rgba(0, 0, 0, 0.80)', paddingTop: '5px', margin: '0px'  }} className={classes.descriptionArea}>
                {data.description.en}
              </div>
            )}
            {(data.description && data.description.en && isMobile && !isPortrait) && (
              <div style={{ width: '325px', height: '100px', backgroundColor: 'rgba(0, 0, 0, 0.80)', paddingTop: '5px', margin: '0px'  }} className={classes.descriptionArea}>
                {(data.description.en) ? data.description.en : ''}
              </div>
            )}
          </div>
        </div>
        {!isMobile && data.description && data.description.en && (
          <div className={classes.descriptionArea}>
            {(data.description.en) ? data.description.en : ''}
          </div>
        )}
        {!isMobile && (
          <TradingViewWidget data={data} />
        )}
   
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
}
