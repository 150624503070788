import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider, createTheme } from '@material-ui/core/styles'; // Import for Material-UI v4

// Create a theme instance
const theme = createTheme(); // If needed, you can customize your theme here

// Assuming your HTML file has a div with an id of 'root'
const container = document.getElementById('root');

// Create a root
const root = ReactDOM.createRoot(container);

// Initial render: Render an element to the root.
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>  {/* Wrap App in ThemeProvider */}
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
