import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import { SERVER_NAME } from './config';
import { jsPDF } from 'jspdf'; // Import jsPDF
import { FaDownload } from 'react-icons/fa';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    overlay: {
        position: 'fixed',
        inset: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1300,
        margin: 0,
        padding: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),  // Keep it aligned to the right
        top: theme.spacing(0.1),  // Reduce the top value to move it higher
        color: theme.palette.grey[500],
        zIndex: 1500,  // Ensure it's above other content
    },
    downloadButton: {
        color: theme.palette.primary.main,
        backgroundColor: '#ff6600',
        color: '#ffffff',
        padding: '3px 6px',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: '#e65c00',
        },
        '& svg': { // Assuming the FontAwesome icon renders as an <svg> element
          fontSize: '0.9em',
        },
    },
    dialogPaper: {
        boxShadow: 'none',  // Keep shadows (adjust the level as needed)
        borderTop: 'none',            // Specifically remove the top border
        border: 'none',              // Remove the border

    },
    dialogTitle: {
        borderTop: 'none', // Ensure there's no border at the top
        borderBottom: 'none', // This removes the bottom border
        paddingBottom: 0,
        paddingTop: 0, // To minimize space
 
    },
    dialog: {
        width: '90%',
        maxWidth: '115vw', // Full width for mobile, custom width for desktop
        margin: '0',
        paddingTop: 0, // Reduce space at the top of the dialog
    },
    content: {
        marginTop: 0, // Remove or reduce margin to decrease space between title and content
        overflowX: 'hidden',            // Prevent horizontal scrolling
    },
    contentMobile: {
        marginTop: 0, // Remove or reduce margin to decrease space between title and content
        overflowX: 'hidden',            // Prevent horizontal scrolling
    },
    container: {
        width: '100%',
        overflowX: 'hidden', // Prevent horizontal overflow
    },
}));

export const Newsletter = ({ open, onClose }) => {
    const classes = useStyles();
    //const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [newsletterHtml, setNewsletterHtml] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Function to update window width on resize
    const handleResize = () => {
        setWindowWidth(window.innerWidth - 50);
    };

    // Add and clean up event listener for window resize
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Determine if mobile based on width
    const isMobile = windowWidth <= 1000;

    // Function to handle injecting HTML and executing scripts
    function injectNewsletterContent(htmlContent) {
        setNewsletterHtml(htmlContent); // Set the HTML content, React will handle rendering

        // After setting the HTML content, handle script execution
        setTimeout(() => {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = htmlContent;

            // Extract script tags
            const scripts = tempDiv.querySelectorAll('script');
            scripts.forEach(script => {
                const newScript = document.createElement('script');
                if (script.src) {
                    newScript.src = script.src;
                } else {
                    newScript.textContent = script.textContent;
                }
                document.body.appendChild(newScript);  // Append the script to the body to execute it
            });
        }, 100); // Adjust timeout if necessary
    }

    // Fetch the latest newsletter content
    useEffect(() => {
        if (open) {
            const fetchNewsletter = async () => {
                try {
                    setLoading(true);
                    const response = await axios.get(`${SERVER_NAME}/getLatestNewsletter`);
                    if (response.status === 200) {
                        injectNewsletterContent(response.data); // Inject the newsletter content
                    } else {
                        setError('Failed to fetch the latest newsletter');
                    }
                } catch (error) {
                    setError('Error fetching the latest newsletter');
                } finally {
                    setLoading(false);
                }
            };
            fetchNewsletter();
        }
    }, [open]);

    // Attach the "Download PDF" event listener after the content is loaded
    useEffect(() => {
        if (!loading && !error) {
            const downloadButton = document.getElementById('downloadPDF');
            if (downloadButton) {
                downloadButton.addEventListener('click', handleDownloadPDF);
            }

            return () => {
                if (downloadButton) {
                    downloadButton.removeEventListener('click', handleDownloadPDF);
                }
            };
        }
    }, [loading, error, newsletterHtml]);

    // Function to handle PDF download
    const handleDownloadPDF = () => {
        const doc = new jsPDF();
        doc.html(document.querySelector('.container'), {
            callback: function (doc) {
                doc.save('E3D_Newsletter.pdf');
            },
            x: 10,
            y: 10,
            width: 180,
            windowWidth: 900,
        });
    };

    return (
        <Dialog 
            open={open && !loading} 
            onClose={onClose} 
            aria-labelledby="newsletter-dialog-title" 
            className={classes.overlay}
            classes={{ paper: classes.dialog }} // Add responsive dialog class
            fullWidth={true} // Ensure it takes up full width of the container
            maxWidth={false}
            style={{ width: isMobile ? windowWidth : '1200px', maxWidth: isMobile ? windowWidth : '1200px' }} // Inline style for width
        >
            <DialogTitle id="newsletter-dialog-title" className={classes.dialogTitle}>
                <Tooltip title="Download as PDF">
                    <IconButton onClick={() => handleDownloadPDF()} className={classes.downloadButton}>
                        <FaDownload /> 
                    </IconButton>
                </Tooltip>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={isMobile ? classes.contentMobile : classes.content}>
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <div
                        id="newsletter"
                        className={classes.container}
                        dangerouslySetInnerHTML={{ __html: newsletterHtml }} // React injects the content
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

