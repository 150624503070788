import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, IconButton, Slider, Typography, Checkbox } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    overlay: {
        position: 'fixed',
        inset: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1300, // Make sure this is above everything else
        margin: 0,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
    },
  optionsPopup: {
    position: 'fixed',
    width: '90%', // Increase this value to make the popup wider
    maxWidth: 500, // Increase this value as well if necessary
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'lightgray',
    padding: 20,
    borderRadius: 10,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    width: '80%',
    maxWidth: 400,
  },
  option: {
    margin: '10px 0',
  },
  label: {
    display: 'block',
    marginBottom: 5,
  },
  range: {
    width: '100%',
  },
  closeButton: {
    float: 'right', // This will move the close icon to the right
  },
}));



export const OptionsPopup = ({ open, onClose, tourSpeedGraph, setTourSpeedGraph, tourSpeedList, setTourSpeedList ,spinSpeed, setSpinSpeed, useAspectRatio, setUseAspectRatio, imageSize, setImageSize, resetCookieConsent, setResetCookieConsent }) => {
    const classes = useStyles();
  
    const handleSliderChange = (event, newValue, setStateFunc) => {
      setStateFunc(newValue);
    };
  
    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" className={classes.overlay}>
        <DialogTitle id="form-dialog-title">
          Options
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography id="tour-speed-slider" gutterBottom>
            Graph View Tour Delay (ms)
          </Typography>
          <Slider
            value={tourSpeedGraph}
            onChange={(e, newValue) => handleSliderChange(e, newValue, setTourSpeedGraph)}
            aria-labelledby="tour-speed-slider"
            min={1000}
            max={10000}
          />
          <Typography id="tour-speed-slider" gutterBottom>
            List View Tour Delay (ms)
          </Typography>
          <Slider
            value={tourSpeedList}
            onChange={(e, newValue) => handleSliderChange(e, newValue, setTourSpeedList)}
            aria-labelledby="tour-speed-slider"
            min={500}
            max={5000}
          />
          <Typography id="spin-speed-slider" gutterBottom>
            Spin Delay (ms)
          </Typography>
          <Slider
            value={spinSpeed}
            onChange={(e, newValue) => handleSliderChange(e, newValue, setSpinSpeed)}
            aria-labelledby="spin-speed-slider"
            min={1}
            max={1000}
          />
           <Typography id="spin-speed-slider" gutterBottom>
            Graph Node Image Size
          </Typography>
          <Slider
            value={imageSize}
            onChange={(e, newValue) => handleSliderChange(e, newValue, setImageSize)}
            aria-labelledby="spin-speed-slider"
            min={20}
            max={200}
          />
          <Typography id="aspect-ratio-checkbox" gutterBottom>
            Fill Image to Window
          </Typography>
          <Checkbox
            checked={useAspectRatio}
            onChange={(e) => setUseAspectRatio(e.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        <Typography id="aspect-ratio-checkbox" gutterBottom>
            Reset Cookie Consent
          </Typography>
          <Checkbox
            checked={resetCookieConsent}
            onChange={(e) => setResetCookieConsent(e.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </DialogContent>
      </Dialog>
    );
  };