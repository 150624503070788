import React, { useState, useEffect } from 'react';
import { Modal, Button, TextField, Box } from '@material-ui/core';
import { List, ListItem, ListItemText } from '@material-ui/core';


export const LoadMapDialog = ({ open, onClose, maps, onLoad }) => {
    const [selectedMap, setSelectedMap] = useState('');

    const handleLoad = () => {
        onLoad(selectedMap);
    };

    useEffect(() => {
        if (maps.length > 0) {
            setSelectedMap(maps[0]);
        }
    }, [maps]);

    return (
        <Modal open={open} onClose={onClose}>
            <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: 20 }}>
                <List component="nav">
                    {maps.map((mapName, index) => (
                        <ListItem button selected={selectedMap === mapName} onClick={() => setSelectedMap(mapName)} key={index}>
                            <ListItemText primary={mapName} />
                        </ListItem>
                    ))}
                </List>
                <Button onClick={handleLoad} color="primary">
                    Load
                </Button>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
            </Box>
        </Modal>
    );
};

export const  SaveMapDialog = ({ open, onClose, onSave }) => {
    const [mapName, setMapName] = useState('');

    const handleSave = () => {
        // Call the onSave function passed from the parent component
        onSave(mapName);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: 20 }}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Map Name"
                    type="text"
                    fullWidth
                    value={mapName}
                    onChange={(e) => setMapName(e.target.value)}
                />
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
            </Box>
        </Modal>
    );
}

