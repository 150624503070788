import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import 'chartjs-adapter-date-fns'; // This adapter is for date formatting, ensure to install it via npm if you haven't

const useStyles = makeStyles((theme) => ({
    chartOverlay: {
      position: 'absolute', // Keep it positioned absolutely within a relative container
      bottom: '40px', // Lift it up a bit from the bottom
      left: '50px', // Move it towards the left side
      zIndex: 1300, // Ensure it stays on top
      color: 'white', // Keep text color white for visibility
      padding: theme.spacing(1),
    },
    chartContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: '50px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Maintain the semi-transparent background
      margin: '0px auto',
      maxWidth: '100%',
      maxHeight: '100px',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
      padding: '10px',
      borderRadius: '5px',
      fontFamily: '"Arial", sans-serif',
      lineHeight: 1.6,
      color: '#333',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
}));


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    TimeScale
);

const FutcoChart = ({ rawData, showList, onClose }) => {
    const classes = useStyles();
    // Convert volumes map to arrays for labels and data
    const labels = Object.keys(rawData);
    //console.log(labels);
    const data = Object.values(rawData);

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Volume',
                data,
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'minute', // Or your desired time unit
                    displayFormats: {
                        minute: 'HH:mm' // 24-hour format
                    }
                },
                ticks: {
                    autoSkip: true, 
                    maxTicksLimit: 20,
                    maxRotation: 90, // Rotates labels to avoid overlap; adjust as needed
                    minRotation: 45, // You can set this as needed to improve label visibility
                    source: 'labels'
                }
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Transactions',
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        var label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat().format(context.parsed.y);
                        }
                        return label;
                    },
                },
            },
        },
        onClick: (event, elements, chart) => {
            if (elements.length > 0) {
                const index = elements[0].index;
                const timestamp = labels[index];
                console.log('Clicked timestamp:', timestamp);
                // Here you can use the clicked timestamp for whatever you need
            }
        },
    };
    
    useEffect(() => {
        const overlayContainer = document.getElementById('overlayContainer');
   
        if (overlayContainer) {
            overlayContainer.style.bottom = `1px`; 
        }
    }, [showList]); // Empty array ensures that effect runs only on mount and unmount

    return (
        <div id="overlayContainer" className={classes.chartOverlay}>
            <div className={classes.chartContainer}>
                <Bar data={chartData} options={options} />
            </div>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </div>
    );
};

export default FutcoChart;